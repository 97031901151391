import React, {useCallback} from 'react'
import { canUseDOM } from 'fbjs/lib/ExecutionEnvironment'
import { Alert, Linking, StyleSheet, Pressable } from "react-native"


const openUrl = async (url) => {
    // Checking if the link is supported for links with custom HREF scheme.
    const supported = await Linking.canOpenURL(url);
    if (!supported)
	return false
    // Opening the link with some app, if the HREF scheme is "http" the web link should be opened
    // by some browser in the mobile
    if (canUseDOM)
        window.open(url, '_blank')
   else 
       await Linking.openURL(url)
    return true
}    

const Link = ({ href, children }) => {
    const onPress = useCallback(
	async () => await openUrl(href) || Alert.alert(`Don't know how to open this HREF: ${href}`),
	[href])

    return <Pressable style={styles.link} onPress={onPress}>{children}</Pressable>
};
const styles = StyleSheet.create({
    link: {
	fontVariant: ['small-caps'],
	cursor: 'pointer'
    },
})
export default Link
export {openUrl}
