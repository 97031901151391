import React, {useState, useCallback, useEffect, useMemo} from 'react';
import {StyleSheet} from 'react-native'
import {Button, Card, Paragraph, Avatar, Text} from 'react-native-paper'
import Icon from 'react-native-vector-icons/FontAwesome'
import Colors from './Colors'
import {openUrl} from './Link'
import Fade from 'react-reveal/Fade'
import Jump from 'react-reveal/Jump'

import Analyze from './analyze'

const EMAIL = process.env.REACT_APP_EMAIL

const Screen = ({next, children, isLast=false}) => {
    const [opacity, setOpacity] = useState(1),
          iconStyle = useMemo(() => StyleSheet.compose(styles.icon,{opacity}), [opacity])
    
    useEffect(
        () => {
            const handler = setTimeout(() => setOpacity(opacity ? 0 : 1), (opacity ? 2750 : 1750))
            return () => clearTimeout(handler)
        },
        [opacity]
    )
             
    return <Paragraph style={styles.paragraph}>
             { children }
             <Icon
               name={isLast ? 'arrow-down' : 'arrow-right'}
               style={iconStyle}
               size={17}
               onPress={next} />
           </Paragraph>
}

const Screens = [
    (
        <>
          We leverage our expertise in the modern technology stack to help you establish your online
          presence in the cloud, automate your business processes, and gain the deeper insights into
          your customers needed to competitively promote your brand across the web and social media
        </>
    ),
    (
        <>
          Today it takes more than just SEO - from custom mobile-first website and app development
          to e-commerce and content databases, 3rd-party software and API integrations,
          digital advertising, online payments, ticketing, and managing the infrastructure to run it all on -
          reliably, and at scale
        </>
    ),
    (
        <>
          We are backed by 20 years of experience from Miami, to New York City, San Francisco,
          and Washington, DC, having implemented technology for digital media teams at iconic brands such as
          American Express, The Economist Group, Major League Baseball, MTV, PBS, and Univision
        </>
    ),
    (
        <>
          Let us save you time and put technology to work for you - whether it's optimizing your
          day-to-day operations and data, engaging your existing audiences more effectively,
          or discovering untapped new ones waiting for growth...{"\n\n"}
          Reach out.
        </>
    )
]
const CardWrapper = () => {
    const [idx, setIdx] = useState(0),
          screen = Screens[idx],
          next = useCallback(() => {
              Analyze('screen_view', {'screen_name': 'slide_' + idx + 1})
              setIdx((idx >= (Screens.length - 1)) ? 0 : (idx + 1))
          }, [idx]),
          jump = useMemo(() => idx === (Screens.length - 1), [idx]),
          onPress = useCallback(() => openUrl(`mailto:${EMAIL}?subject=Inquiry`), []),
          isLast = useMemo(
              () => idx === ((Screens?.length || 0) - 1),
              [idx]
          )
    
    return <Card style={styles.card}>
              <Card.Title
                title={<Text>Agency EMB</Text>}
                subtitle={<Text style={{color: Colors.red}}>Technology Consultancy</Text>}
                left={
                    props => <Avatar.Text
                          {...props}
                          style={styles.avatar}
                          label="Æ"
              />
                }
              />
             <Card.Content style={styles.cardContent}>
               <Screen next={next} isLast={isLast}>{screen}</Screen>
             </Card.Content>
             <Card.Actions style={styles.cardActions}>
               <Text style={styles.contact}>
                 <Fade opposite delay={1250} duration={1500} >
                   <Jump delay={800} when={jump} duration={2400}>
                     <Button
                       style={styles.button}
                       color={'rgb(200,175,175)'}
                       icon="email"
                       accessibilityLabel="Email"
                       onPress={onPress}
                     >
                       <Text style={{color: 'black'}}>
                         {EMAIL}
                       </Text>
                     </Button>
                   </Jump>
                 </Fade>
               </Text>
             </Card.Actions>
           </Card>
}

const styles = StyleSheet.create({
    card: {
        backgroundColor: 'rgba(255,255,255,0.5)',
        maxHeight: 420,
        border: `1px #A8A8A8 solid`,
        borderRadius: 15
    },
    avatar: {
        backgroundColor: Colors.red
    },
    cardContent: {
	height: 180,
        backgroundColor: 'transparent'
    },
    contact: {
        flex: 1,
        textAlign: 'right'
    },
    paragraph: {
        backgroundColor: 'transparent'
    },
    button: {
        cursor: 'pointer',
        color: Colors.red
    },
    icon: {
        cursor: 'pointer',
        color: Colors.red,
        padding: 8
    }
})

export default CardWrapper

