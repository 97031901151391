// Use prebuilt version of RNVI in dist folder
// import Icon from 'react-native-vector-icons/dist/FontAwesome5';

// Generate required css
import FontAwesome from 'react-native-vector-icons/Fonts/FontAwesome.ttf'
import FontAwesome5Brands from 'react-native-vector-icons/Fonts/FontAwesome5_Brands.ttf'
import FontAwesome5Regular from 'react-native-vector-icons/Fonts/FontAwesome5_Regular.ttf'
import FontAwesome5Solid from 'react-native-vector-icons/Fonts/FontAwesome5_Solid.ttf'
import MaterialCommunityIcons from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf'

const iconFontStyles = `
@font-face {
  src: url(${FontAwesome});
  font-family: FontAwesome;
  font-display: swap;
}
@font-face {
  src: url(${FontAwesome5Brands});
  font-family: FontAwesome5_Brands;
  font-display: swap;
}
@font-face {
  src: url(${FontAwesome5Regular});
  font-family: FontAwesome5_Regular;
  font-display: swap;
}
@font-face {
  src: url(${FontAwesome5Solid});
  font-family: FontAwesome5_Solid;
  font-display: swap;
}
@font-face {
  src: url(${MaterialCommunityIcons});
  font-family: MaterialCommunityIcons;
  font-display: swap;
}


`

// Create stylesheet
const style = document.createElement('style')
style.type = 'text/css'

if (style.styleSheet)
    style.styleSheet.cssText = iconFontStyles;
else
    style.appendChild(document.createTextNode(iconFontStyles));


// Inject stylesheet
document.head.appendChild(style);
