const Analyze = (eventName, attrs) => {
    /*global gtag*/
    /*eslint no-undef: "error"*/
    if (typeof(gtag) !== 'undefined') {
	// console.debug({eventName, attrs})
        return Promise
	    .resolve(() => gtag('event', eventName, attrs))
	    .then(_ => _())
	    .catch(error => console.error(error))
    } else {
	console.info('no transport')
    }
    return Promise.resolve(null)
}
export default Analyze
