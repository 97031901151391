import React, {useState,useEffect,useRef,useMemo} from 'react';
import {View, ScrollView, StyleSheet, useWindowDimensions, SafeAreaView,} from 'react-native'
import {Text} from 'react-native-paper'
import * as THREE from 'three'
import GLOBE from 'vanta/dist/vanta.globe.min'
import Card from './Card'
import './App.css'
import Link from './Link'

function App() {
    const [bgEffect, setBgEffect] = useState(0),
          dimensions = useWindowDimensions(),
	  layoutRef = useRef(null),
          backgroundStyle = useMemo(() => [
              styles.background,
              {
                  height: dimensions.height * 0.999,
                  width: dimensions.width * 0.999
              }
          ], [dimensions]),
          scrollViewStyle = useMemo(() => [
              styles.scrollView,
              {
                  top: (dimensions.height / 3) - 50,
                  width: 320
              }
          ], [dimensions]),
          footerViewStyle = useMemo(() => [
              styles.footerView,
              {
                  width: dimensions.width * 0.999
              }
          ], [dimensions])
    
    useEffect(() => {
        if (!bgEffect && layoutRef?.current) {
            setBgEffect(
                GLOBE({
                    el: layoutRef.current,
                    THREE,
		    mouseControls: true,
		    touchControls: true,
		    gyroControls: true,
                    minHeight: 320,
                    minWidth: 320,
		    scale: 0.3,
		    scaleMobile: 0.5,
		    color: 0x2b2a2a,
		    color2: 0xd94d4d,
		    backgroundColor: 0xffffff
		})
            )
        }
	return () => {
            if (bgEffect && bgEffect.destroy)
                bgEffect.destroy()
        }
    }, [bgEffect, layoutRef])

    return <div className="App">
             <SafeAreaView style={styles.safeAreaView}>
               <View style={backgroundStyle} ref={layoutRef} />
               <ScrollView style={scrollViewStyle}>
                 <Card />
               </ScrollView>
               <View style={footerViewStyle}>
                 <Text style={styles.footerText} numberOfLines={2}>
                   We are a <Link href="https://blackcatalog.io"><Text>Black and minority</Text></Link>-owned venture.
                   {"\n"}
                   <Text style={styles.copy}>&copy; 2020 Agency EMB - Miami, Florida</Text>
                 </Text>
               </View>
             </SafeAreaView>
           </div>
}


    const styles = StyleSheet.create({
	bold: {
	    fontWeight: 'bold'
	},
    safeAreaView: {
	flex: 1,
	flexDirection: 'column',
    },
    background: {
        position: 'sticky',
    },
    scrollView: {
        flexDirection: 'column',
        position: 'absolute',
        left: 20,
        backgroundColor: 'rgba(255,255,255,0.5)',
        padding: 10
    },
    footerView: {
        position: 'absolute',
        bottom: 5,
        left: 0,
        backgroundColor: 'rgba(255,255,255,0.5)',

    },
    footerText: {
        fontSize: 12,
        textAlign: 'center'
    },
    copy: {
        fontVariant: ['small-caps']
    }
    

})
export default App

